<template>
  <div class="page_body">
    <div class="box">
      <div class="top_box">
        <div class="top_box_title">
          <div class="title">全省教育联采排行</div>
        </div>
      </div>
      <div class="content">
        <!--  -->
        <div class="content_item">
          <div class="c_topbox">
            <el-row class="elbut_lst">
              <h3>入围供应商名单公示</h3>
            </el-row>
            <div class="c_top_search">
              <el-button class="margin_right_20">查看全部</el-button>
              <el-input placeholder="请输入供应商名称搜索" class="input-with-select">
                <el-button slot="append" icon="el-icon-search">搜索</el-button>
              </el-input>
            </div>
          </div>
          <div class="c_body">
            <el-table :data="tableLCData_gys" style="width: 100%">
              <el-table-column align="left" prop="address" label="供应商名称">
                <template slot-scope="scope">
                  <div class="table_address_body">
                    <span v-if="scope.$index === 0" class="title_num num1">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 1" class="title_num num2">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 2" class="title_num num3">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index > 2" class="title_num num0">{{scope.$index + 1}}</span>
                    <span>&nbsp;&nbsp;</span>
                    <el-tooltip placement="bottom">
                      <div slot="content">
                        <div class="tooltip_box">{{scope.row.title}}</div>
                      </div>
                      <span class="font_one_row">{{scope.row.title}}</span>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="value" label="供应商类别" width="120">
              </el-table-column>
            </el-table>
            <div class="content_footer">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 50, 100, 200]" :page-size="pageLimit"
                layout="total, sizes, prev, pager, next, jumper" :total="136">
              </el-pagination>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="content_item">
          <div class="c_topbox">
            <el-row class="elbut_lst">
              <h3>采购商排行榜</h3>
            </el-row>
            <div class="c_top_search">
              <el-button class="margin_right_20">查看全部</el-button>
              <el-input placeholder="请输入采购商名称搜索" class="input-with-select">
                <el-button slot="append" icon="el-icon-search">搜索</el-button>
              </el-input>
            </div>
          </div>
          <div class="c_body">
            <el-table :data="tableLCData_cgs" style="width: 100%">
              <el-table-column align="left" prop="address" label="采购商">
                <template slot-scope="scope">
                  <div class="table_address_body">
                    <span v-if="scope.$index === 0" class="title_num num1">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 1" class="title_num num2">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 2" class="title_num num3">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index > 2" class="title_num num0">{{scope.$index + 1}}</span>
                    <span>&nbsp;&nbsp;</span>
                    <el-tooltip placement="bottom">
                      <div slot="content">
                        <div class="tooltip_box">{{scope.row.title}}</div>
                      </div>
                      <span class="font_one_row">{{scope.row.title}}</span>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="value" label="金额（元）" width="120">
              </el-table-column>
            </el-table>
            <div class="content_footer">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 50, 100, 200]" :page-size="pageLimit"
                layout="total, sizes, prev, pager, next, jumper" :total="136">
              </el-pagination>
            </div>
          </div>
        </div>
        <!--  -->
        <div class="content_item">
          <div class="c_topbox">
            <el-row class="elbut_lst">
              <h3>价格行情</h3>
            </el-row>
            <div class="c_top_search">
              <el-button class="margin_right_20">查看全部</el-button>
              <el-input placeholder="请输入商品名称搜索" class="input-with-select">
                <el-button slot="append" icon="el-icon-search">搜索</el-button>
              </el-input>
            </div>
          </div>
          <div class="c_body">
            <el-table :data="tableLCData_money" style="width: 100%">
              <el-table-column align="left" prop="address" label="商品名称">
                <template slot-scope="scope">
                  <div class="table_address_body">
                    <span v-if="scope.$index === 0" class="title_num num1">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 1" class="title_num num2">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index === 2" class="title_num num3">{{scope.$index + 1}}</span>
                    <span v-if="scope.$index > 2" class="title_num num0">{{scope.$index + 1}}</span>
                    <span>&nbsp;&nbsp;</span>
                    <el-tooltip placement="bottom">
                      <div slot="content">
                        <div class="tooltip_box">{{scope.row.title}}</div>
                      </div>
                      <span class="font_one_row">{{scope.row.title}}</span>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" prop="value" label="联采指导价" width="120">
              </el-table-column>
            </el-table>
            <div class="content_footer">
              <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="currentPage" :page-sizes="[10, 20, 50, 100, 200]" :page-size="pageLimit"
                layout="total, sizes, prev, pager, next, jumper" :total="136">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    mapGetters
  } from 'vuex';
  export default {
    name: 'bigdata_fupin_catalog',
    components: {},
    data: () => {
      return {
        tableLCData_gys: [{
          title: "湖南银光粮油股份有限公司",
          value: "大米",
        }, {
          title: "湖南湘西八件宝农业有限责任公司",
          value: "菜油、茶油",
        }, {
          title: "溆浦绿之然农业有限公司",
          value: "米、菜油、茶油",
        }, {
          title: "湖南湘西八件宝农业有限责任公司",
          value: "菜油、茶油",
        }, {
          title: "溆浦绿之然农业有限公司",
          value: "米、菜油、茶油",
        }, {
          title: "溆浦绿之然农业有限公司",
          value: "米、菜油、茶油",
        }, {
          title: "湖南湘西八件宝农业有限责任公司",
          value: "菜油、茶油",
        }, {
          title: "溆浦绿之然农业有限公司",
          value: "米、菜油、茶油",
        }, ],
        tableLCData_cgs: [{
          title: "湘一立信实验学校-锦茂食堂",
          value: "100万",
        }, {
          title: "食品药品业学(含浦校区)-锦茂食堂",
          value: "87万",
        }, {
          title: "湖南工业大学-锦茂食堂",
          value: "67万",
        }, {
          title: "食品药品业学(含浦校区)-锦茂食堂",
          value: "87万",
        }, {
          title: "湖南工业大学-锦茂食堂",
          value: "67万",
        }, {
          title: "长沙理工大学云塘校区-锦茂食堂",
          value: "67万",
        }, {
          title: "湖南工业大学-锦茂食堂",
          value: "67万",
        }, {
          title: "白鹤菱溪中学-锦茂食堂",
          value: "67万",
        }, ],
        tableLCData_money: [{
          title: "大米（帮扶产品）",
          value: "2.36",
        }, {
          title: "鸡蛋（帮扶产品）",
          value: "1.3",
        }, {
          title: "菜籽油（帮扶产品）",
          value: "1.3",
        }, {
          title: "油麦菜",
          value: "1.3",
        }, {
          title: "黄皮土豆",
          value: "1.3",
        }, {
          title: "白花菜",
          value: "1.3",
        }, {
          title: "紫长茄",
          value: "1.3",
        }, {
          title: "西红柿",
          value: "1.3",
        }, ],
        // 当前单位性质
        budgetOn: "不限",
        tableTitleDatas: [{
          name: '单位个数',
          untis: '（个）',
          value: 15,
        }, {
          name: '采购总金额',
          untis: '（万元）',
          value: 20182546.34,
        }, ],
        // 单位分类列表
        buttonlst: [{
          id: 1,
          type: 'primary',
          name: '不限',
        }, {
          id: 2,
          type: '',
          name: '预算单位',
        }, {
          id: 3,
          type: '',
          name: '非预算单位',
        }, ],
        tableData_chongzhi: [{
          address: '长沙农商银行',
          bi: 99,
          jinger: '584956.52',
        }, {
          address: '湘阴县文星镇城关中学',
          bi: 68,
          jinger: '584956.52',
        }, {
          address: '邵阳县人民医院',
          bi: 78,
          jinger: '584956.52',
        }, {
          address: '湘阴县文星镇城关中学',
          bi: 89,
          jinger: '584956.52',
        }, {
          address: '邵阳县人民医院',
          bi: 19,
          jinger: '584956.52',
        }, {
          address: '邵阳县人民医院',
          bi: 84,
          jinger: '584956.52',
        }, {
          address: '湘阴县文星镇城关中学',
          bi: 89,
          jinger: '584956.52',
        }, {
          address: '邵阳县人民医院',
          bi: 84,
          jinger: '584956.52',
        }, {
          address: '湘阴县文星镇城关中学',
          bi: 89,
          jinger: '584956.52',
        }, {
          address: '邵阳县人民医院',
          bi: 69,
          jinger: '584956.52',
        }, ],
        currentPage: 1,
        pageLimit: 10,
      };
    },
    created() {},
    computed: {
      ...mapGetters(['defaultHeadImage', 'defaultGoodsImage'])
    },
    watch: {
      catalogValue(olddata, newdata) {
        console.log("catalogValue...", olddata, newdata);
      }
    },
    mounted() {
      this.getTableData();
    },
    methods: {
      // 修改按钮状态
      changeBut(option) {
        this.buttonlst.map((item, index) => {
          item.type = '';
          if (item.id === option.id) {
            item.type = 'primary';
          }
        })
      },
      // 跳转到对应的链接
      toPage(option) {
        console.log("...toPage...", option);
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageLimit = val;
        this.getTableData();
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.currentPage = val;
        this.getTableData();
      },
      getTableData() {
        this.tableData = this.tableDataGet.slice((this.currentPage - 1) * this.pageLimit, this.currentPage * this
          .pageLimit);
      },
    },
  };
</script>
<style lang="scss" scoped>
  $color_font0: #797979;
  $color_font1: #555555;
  $color_font20: #3d3d3d;
  $color_font2: #303030;
  $color_waring: #D81E01;

  .el-main {
    position: relative;
  }

  .page_body {
    box-sizing: border-box;
    width: 100% !important;
    background-color: rgba(247, 247, 247);
    background-image: url('/public/static/img/bj.jpg');
    background-repeat: no-repeat;
    background-size: 100% 360px;
  }

  .box {
    max-width: $width;
    margin: 0 auto;
    padding-bottom: 50px;
    box-sizing: border-box;

    .top_box {
      padding-top: 30px;
      padding-bottom: 30px;
      color: #FFFFFF;
      box-sizing: border-box;

      .top_box_select {
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 30px;

        .el_select {
          width: 360px;
        }
      }

      .top_box_content {
        box-sizing: border-box;
        padding: 20px 40px 0 40px;
        text-indent: 25px;
      }

      .top_box_title {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 0 20px;

        .title {
          font-size: 24px;
          width: 1090px;
        }

        .date {
          font-size: 14px;
          width: 260px;
          text-align: right;
        }
      }
    }

    .content {
      width: 1210px;
      box-sizing: border-box;

      .content_item {
        box-sizing: border-box;
        margin-bottom: 30px;

        .c_topbox {
          height: 108px;
          line-height: 68px;
          font-size: 16px;
          background-color: rgba(232, 238, 247);
          padding: 20px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;

          .c_top_select {
            width: auto;
            padding-right: 40px;
            font-size: 14px;
          }

          .c_top_search {
            width: 490px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .c_title_l {
            display: flex;
            align-items: center;

            span {
              color: #ff0000;
            }

            .title_img {
              margin-left: 20px;
              width: 26px !important;
              height: 20px !important;

              img {
                width: 26px !important;
                height: 20px !important;
              }
            }
          }
        }

        .c_body {
          box-sizing: border-box;
          background-color: #FFFFFF;
          padding: 20px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;

          .c_b_top {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin: 10px 0 30px 0;

            .top_item {
              width: 288px;
              display: flex;
              flex-direction: column;

              .number {
                text-align: center;
                font-size: 38px;
                font-weight: 800;
                color: rgba(114, 114, 114);
                font-stretch: condensed;
                line-height: 40px;
              }

              .names {
                text-align: center;
                line-height: 24px;
              }
            }
          }

          .cb_box {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }

          .content_footer {
            height: 108px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .table_address_body {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .title_num {
      width: 42px;
      font-size: 24px;
      font-weight: 800;
      text-align: right;
      padding-bottom: 5px;
    }

    .num0 {
      color: rgb(89, 74, 74);
    }

    .num1 {
      color: rgb(252, 1, 1);
    }

    .num2 {
      color: rgb(253, 200, 3);
    }

    .num3 {
      color: rgb(90, 206, 1);
    }
  }

  .margin_left_20 {
    margin-left: 20px;
  }

  .margin_right_20 {
    margin-right: 20px;
  }
</style>